<template>
  <div class="container">
    <div class="row mb-3 margin-top-0421">
      <div class="col-sm-12">
        <img
          src="/imgc/blog/10/diwali-homepage.webp"
          class="img-fluid rounded w-100 img-thumbnail"
          alt="How you can celebrate festive seasons like never before"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-primary">How you can celebrate festive seasons like never before</h1>
        <small>
          <i class="fa fa-clock text-secondary"></i> 25 10, 2021 |
          <i class="fa fa-user text-secondary"></i> Admin
        </small>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <p>
          Our generation has seen a lot of changes in their lifestyle, especially in how we
          celebrate festivals like Diwali! From spending time with friends and family, to
          discovering new things such as redecorating our houses or travelling around India; it’s
          important that we understand these changes as well as how they made us the way we are! How
          2020 changed the way we celebrate the festive seasons! Due to the pandemic, a lot has
          changed the way people travel or celebrate festivals like Diwali in India. COVID-19 has
          the world on lockdown. However, this year, PM Modi has instructed that festivities be
          celebrated in public spaces and make it as safe as possible for all.
        </p>
        <p>
          In such a situation, all we can do is adapt our habits and act as responsibly as we can.
          Although this change might not always be liked by people, but it is for the best.
        </p>
        <p>
          While we might not be able to travel home or visit our family and friends, there are still
          things we can do! Here’s how you can still celebrate the festival in all its glory.
        </p>
        <img
          src="/imgc/blog/10/diwali.jpg"
          class="img-fluid rounded w-100 img-thumbnail"
          alt="diwali festival indian"
        />
        <h3>GIVING BACK</h3>
        <p>
          All the festive lights create a lot of waste and pollution. So , try and go eco-friendly
          this Diwali by gifting your loved ones environmentally safe gifts or decorating your house
          with flowers and plants which can be composted after the festival.
        </p>
        <h3>ECO-FRIENDLY HOMES</h3>
        <p>
          We’ve been hearing about the eco-friendly homes that the Prime Minister has visited and
          admired, so why not make it yours as well? Eco friendly homes are specially designed in a
          way where they save energy. This not only means saving electricity but also saving money.
          This Diwali, apart from decorating your home with decorative lights, you could also
          decorate your home with energy saving diyas and lights.
        </p>
        <img
          src="/imgc/blog/10/diwali-greener.jpg"
          class="img-fluid rounded w-100 img-thumbnail"
          alt="MAKE DIWALI GREENER"
        />
        <h3>MAKE DIWALI GREENER</h3>
        <p>
          Pollution around India during diwali festival is at an all-time high now, and it’s the
          citizens who are suffering the most. So, make your Diwali greener this time by
          distributing eco-friendly gifts. You can also join the crowd, and light up the streets
          with eco-friendly diyas!
        </p>
        <h3>TREAT YOUR SELF AWAY</h3>
        <p>
          Now, you can’t travel back home to visit your family or loved ones this Diwali, but you
          can still celebrate the festival. Treat yourself to a luxurious spa session, or make it a
          movie night at home with your close friends. You can also buy yourself some amazing
          clothes or gadgets that you have been eyeing from a long time!
        </p>
        <img
          src="/imgc/blog/10/diwali-india.jpg"
          class="img-fluid rounded w-100 img-thumbnail"
          alt="diwali festival indian"
        />
        <h3>LET YOUR FAMILY VISIT YOU</h3>
        <p>
          Thanks to intense vaccination opertions that undertook around the world, travelling abroad
          is a bit safer this year, and to make it more convinient for your loved one's, you can
          take help of travel companions to assist your loved one's travelling abroad to see you!
        </p>
        <p>
          If you don't have such an option, then why not make it special for them by using this
          amazing idea: A fixie basket! Get your loved ones fixie basket! It will give them two
          gifts at once! With a fixie basket, you can pamper your loved ones by giving them a box of
          assorted chocolates, filled with some sweets and a cute teddy bear. This gift will give
          your loved one’s two different gifts, and experiences, just in one! Also, fixie baskets
          are very affordable, so you can definitely give this gift to your family and friends this
          Diwali!
        </p>
        <p>
          The biggest change this festive year is, of course, not being able to celebrate it
          traditionally, which is sad, but there's a bright side to it. Technology has advanced, and
          there are a lot of new and useful things on the market. So, if you’d like to celebrate
          this Diwali differently, use these ideas and make this Diwali the best one yet!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Blog10',
  created() {
    document.title = 'Trepr - How you can celebrate festive seasons like never before!';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'How 2020 changed the way we celebrate the festive seasons! Due to the pandemic, a lot has changed the way people travel or celebrate festivals like Diwali in India. COVID-19 has the world on lockdown. However, this year, PM Modi has instructed that festivities be celebrated in public spaces and make it as safe as possible for all '
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, diwali,How you can celebrate festive seasons like never before,Your Best travel apps for the trip, France; Flights have started, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>
